header {
    margin-top: 5rem;
    height: calc(100vh - 35rem);
    display: grid;
    place-items: center;
    background: var(--color-white);
    transition: var(--transition);
}

.header_container {
    display: flex;
    flex-direction: column ;
    align-items: center;
    position: relative;
}

.header_profile {
    width: 25rem;
    height: 22rem;
    border-radius: 5rem 5rem 5rem 5rem;
    overflow: hidden;
    margin-bottom: 1rem;
}

.header_container p {
    width: 35rem;
    text-align: center;
    margin:0.6rem 0 2rem ;
}

.header_cta {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.header_socials{
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header_socials a {
    width: 1.8rem;
    aspect-ratio: 1/1 ;
    background: var(--color-light);
    color: var(--color-black);
    display: grid;
    place-items: center;
    border-radius: 50%;

}

.header_socials a:hover {
    background: var(--color-primary);
    color: var(--color-white);
    transform:translateX(0.5rem);
}

/* TABLET QUERY */
@media screen and (max-width: 1024px)  {
    header {
        margin-top: 0;
        height: 100vh;
    }
}

/* MOBILE */
@media screen and (max-width: 600px)  {
    .header_profile {
        margin-top: 14rem;
        height: 17rem;
        max-width: 60%;
        max-height: 35vh;
    }
    .header_container p {
        width: 100%;
    }

    .header_cta {
        gap: 1rem;
    }

    .header_socials{
        display: none;
    }
}