/* .services_container {
    display: grid;
    grid-template-columns:  1fr 1fr;
    gap: 2rem 4rem;
} */

/* .card.service {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
} */
/* .service_icon {
    background: var(--color-primary);
    padding: 0.6rem;
    border-radius: var(--border-radius-3);
    font-size: 1.5rem;
    color: var(--color-white);
    transition: var(--transition);
} */

/* .service:hover .service_icon {
    background: var(--color-light);
    color: var(--color-primary);
}

.service h4 {
    margin-bottom: 0.7rem;
} */

/* media query for tablets  */
@media screen and (max-width:1024px) {
    .services_container {
        gap: 1.5rem;
    }
}

/* media query for phone  */
@media screen and (max-width:600px) {
    .services_container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
}

/* .card.service{
    gap: 1.2rem;
} */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
 
/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} */

/* #root {
    height: 100vh;
    display: flex;
    background-color: crimson;
    align-items: center;
    justify-content: center;
} */

/* .container{
    position: relative;
    max-width: 500px;
    width: 100%;
    background: #fff;
    margin: 0 15px;
    padding: 10px 20px;
    border-radius: 7px;
} */

.box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 4rem;
    align-items: start;
    transition: var(--transition);
}

/* .title-text {
    text-align: center;
    background-color: crimson;
    color: #fff;
    font-weight: 400;
    border-radius: 50px;
    margin-top: 15px;
    margin-bottom: 30px;
} */

.container .skill-box {
    width: 100%;
    margin: 25px 0;
}

.skill-box .title{
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0,0, 0, 0.1);
}

.skill-box .skill-per{
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 6px;
    background: var(--color-primary);
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
}

.skill-per.css {
    width: 80%;
    animation-delay: 0.1s;
}
.skill-box:hover{
    background: transparent;
    border-color: var(--color-primary);
    transform: translateY(-.5rem);
    transition: var(--transition);
}

.skill-per.html{
    width: 90%;
    animation-delay: 0.3s;
}
.skill-per.javascript{
    width: 85%;
    animation-delay: 0.2s;
}
.skill-per.nodejs{
    width: 70%;
    animation-delay: 0.3s;
}
.skill-per.reactjs{
    width: 87%;
    animation-delay: 0.3s;
}
.skill-per.expressjs{
    width: 70%;
    animation-delay: 0.3s;
}
.skill-per.python{
    width: 70%;
    animation-delay: 0.3s;
}


@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skill-per .tooltip{
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background: var(--color-primary);
    z-index: 1;
}

/* .tooltip::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: var(--color-primary);
    transform: translateX(-50%) rotate(45deg);
} */