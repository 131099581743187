nav {
    width: 100vw;
    height: 5rem;
    display: grid;
    place-items: center;
    background: var(--color-primary);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    box-shadow: 0 1rem 1.5rem hsl(var(--primary-hue), 68%, 42% 20%);
    transition: var(--transition);
}

.nav_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.nav_logo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
}

#theme_icon {
    background: transparent;
    color: white;
    font-size: 3rem;
    cursor: pointer;
    border: none;
}

.nav_menu {
    display: flex;
    gap: 3.5rem;
}

.nav_menu a {
    color: white;
}

/* Hide nav on tablets and phones */
@media screen and (max-width:1024px) {
    .nav_menu {
        display: none;
    }
}