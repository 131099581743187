.portfolio_categories {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap ;
}

.btn.cat_btn {
    text-transform: capitalize;
    transition: var(--transition);
}

.btn.cat_btn:hover {
    transform: translateY(0);
}


.portfolio_projects {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    align-items: start;
}

.card.portfolio_project {
    animation: animateProject 800ms ease-in forwards;
}

@keyframes animateProject {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.portfolio_project-image {
    width: 100%;
    height: fit-content;
    min-height: 7rem;
    max-height: 15rem;
    background: var(--color-light);
    border-radius: var(--border-radius-2);
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.portfolio_project p {
    margin: 0.5rem 0 2rem;
}

.portfolio_project-cta {
    display: flex;
    gap: 1rem;
}

.portfolio_project:hover .btn.sm {
    border-color: var(--color-white);
}

.portfolio_project:hover .btn.sm.primary {
    background: transparent;
    color: var(--color-primary);
}

/* media queries for tablets */
@media screen  and (max-width:1024px ) {
    .portfolio_projects {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

/* media queries for phones */ 
@media screen  and (max-width:600px ) {
    .portfolio_categories {
        gap: 0.6rem;
    }
    .btn.cat_btn {
        padding: 0.7rem 0.8rem;
    }

    .portfolio_projects {
        grid-template-columns: 1fr ;
        gap: 1.5rem;
    }
}