.card.theme_modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    background: #dedede;
    z-index: 200;
    text-align: center;
    box-shadow: 0 3rem 3rem rgba(0,0,0,0.2);
    padding: 3rem;
    opacity:0;
    animation: animateModal 500ms ease-in forwards;

}

@keyframes animateModal {
    to {
        opacity:1
    }
}

.card.theme_modal:hover {
    background: #dedede ;
}

.card.theme_modal small {
    margin-top: 0.6rem;
    display: block;
    width: 80%;
    margin-inline: auto;
}

.theme_primary-wrapper {
    margin: 3rem 0;
}

.theme_primary-colors {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.theme_primary-colors button {
    width: 2rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    cursor: pointer;
}
.theme_primary-colors .color-1{
    background: hsl(270, 70%, 46%);
}

.theme_primary-colors .color-2{
    background: hsl(110, 70%, 46%);
}

.theme_primary-colors .color-3{
    background: hsl(240, 70%, 46%);
}

.theme_primary-colors .color-4{
    background: hsl(325, 70%, 46%);
}

.theme_primary-colors .color-5{
    background: hsl(0, 70%, 46%);
}

.theme_primary-colors .color-6{
    background: hsl(52, 70%, 46%);
}

.theme_background-colors {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
}

.theme_background-colors button {
    width: 7rem;
    height: 2.5rem;
    background: white;
    border-radius: var(--border-radius-3);
    cursor: pointer;
}

.theme_background-colors .bg-2 {
    background: #111;
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .card.theme__modal {
        width: var(--container-width-md);
        padding: 3rem 1.5rem;
    }
    .theme__primary-colors {
        gap: 0.6rem;
    }
}