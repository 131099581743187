#testimonials {
    padding-bottom: 0;
}

.swiper-slide {
    cursor: grab;
}

.swiper {
    padding-top: 1rem;
    height: 30rem;
    padding-bottom: 12rem;
}

.testimonial_client {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.testimonial_client-avatar {
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}

.swiper-pagination {
    margin-bottom: 8rem;
}

.swiper-pagination-bullet {
    background: var(--color-primary);
}

/* media query for  */